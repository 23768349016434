import React from "react"
import Layout from "../components/Layouts/base"
import { PersonalInfoLongForm } from "../components/PersonalInfoForm/LongApp"

export default function Page() {
  return (
    <Layout>
      <PersonalInfoLongForm hasESIA />
    </Layout>
  )
}

import React from "react"

import { PersonalInfoForm, PersonalInfoFormProps } from "./Base"
import { ProgressBarProvider } from "../DynamicProgressBar"

type Props = Omit<PersonalInfoFormProps, "onSubmit">

export function PersonalInfoLongForm(props: Props) {
  return (
    <ProgressBarProvider>
      <PersonalInfoForm {...props} name="longPersonalForm" />
    </ProgressBarProvider>
  )
}
